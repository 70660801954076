import React from 'react'
import { graphql, Link } from 'gatsby'

import { ThemeProvider } from 'styled-components'

import { TitleAndMetaTags } from '../components/Helpers.TitleAndMetaTags'
import { Layout } from '../components/Layout'
import { H1, H3 } from '../components/Typography.Headings'
import { SmallHeroWrapper } from '../components/Layout.Wrapper'
import { Button } from '../components/Common.Button'

import { theme } from '../site'

export default function NotFoundPage({ location, data }) {
  const { markdownRemark } = data
  const { seo } = markdownRemark

  return (
    <Layout location={location}>
      <TitleAndMetaTags {...seo} />
      {/* TODO: Remove ThemeProvider once https://github.com/gatsbyjs/gatsby/issues/5498 is fixed */}
      <ThemeProvider theme={theme}>
        <SmallHeroWrapper alignment="center">
          <H1 color={'blue'} bold>
            404
          </H1>
          <H3 color={'blue'} bold>
            PAGE NOT FOUND
          </H3>
          <Link to={'/'}>
            <Button>Go to Popcry.pt</Button>
          </Link>
        </SmallHeroWrapper>
      </ThemeProvider>
      {/* TODO: Remove ThemeProvider once https://github.com/gatsbyjs/gatsby/issues/5498 is fixed */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query notFoundQuery {
    markdownRemark(fields: { id: { eq: "404" } }) {
      ...PageMetaFragmentMarkdown
    }
  }
`
